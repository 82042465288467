import { useSEOData } from "../../components/layout/SEODataProvider";

export const useIsFrontPage = () => {
  // Using data from seo-context. If we use usePathName instead we don't get the pathname on the server.
  const cannonicalPath = useSEOData()?.canonicalPath;

  if (!cannonicalPath?.length) console.error("Tried to use usIsFrontPage() without access to SEO-data");

  return cannonicalPath === "/";
};
