import { createContext, ReactNode, useContext } from "react";
import { SEOProps } from "../SEO";

const Context = createContext<SEOProps | undefined>(undefined);

export const useSEOData = () => useContext(Context);

interface Props {
  seoProps?: SEOProps;
  children: ReactNode;
}

export const SEODataProvider = (props: Props) => (
  <Context.Provider value={props.seoProps}>{props.children}</Context.Provider>
);
