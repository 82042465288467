import { Collapse, Flex, HStack, Stack } from "@biblioteksentralen/react";
import { usePathname } from "next/navigation";
import { useReducer, useRef } from "react";
import { HeaderSearchInput } from "../../../searchAndCollection/components/search/HeaderSearchInput";
import { SearchViewInput } from "../../../searchAndCollection/components/search/SearchViewInput";
import { useIsOnSearchPage } from "../../../searchAndCollection/hooks/useIsOnSearchPage";
import { useIsFrontPage } from "../../../utils/hooks/useIsFrontPage";
import { HeaderFooterContainer } from "../../ContentContainer";
import InternalLink from "../../InternalLink";
import { headerLinkDecorationStyle } from "../utils";
import { HeaderSearchButton } from "./HeaderSearchButton";
import { HeaderUserInfo } from "./HeaderUserInfo";
import Logo from "./Logo";
import { MinSideLink } from "./MinSideLink";
import { useHeaderNavigationLinks } from "./useHeaderNavigationLinks";

export const Desktop = () => {
  const navigationLinks = useHeaderNavigationLinks();
  const currentPath = usePathname();
  const isFrontPage = useIsFrontPage();
  const isSearchPage = useIsOnSearchPage();
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const [searchIsOpen, toggleSearchIsOpen] = useReducer((s) => !s, isFrontPage || isSearchPage);

  const handleToggleSearchIsOpen = () => {
    toggleSearchIsOpen();
    !searchIsOpen && setTimeout(() => searchInputRef.current?.focus(), 0);
  };

  const SearchComponent = isSearchPage ? SearchViewInput : HeaderSearchInput;

  return (
    <>
      <HeaderFooterContainer as="nav">
        <Stack paddingY="1.5rem" spacing="1.5rem">
          <Flex gap="4rem" justifyContent="space-between" flexWrap="wrap">
            <Logo />
            <HeaderUserInfo />
          </Flex>
          <Flex gap="4rem" justifyContent="space-between" flexWrap="wrap">
            <HStack
              as="ul"
              listStyleType="none"
              spacing={{ base: "2rem", xl: "2.5rem" }}
              flexWrap="wrap"
              justifyContent="center"
            >
              {navigationLinks.map((link) => (
                <li key={link.path}>
                  <InternalLink
                    href={link.path}
                    variant="plain"
                    whiteSpace="nowrap"
                    fontWeight="600"
                    sx={headerLinkDecorationStyle(currentPath.startsWith(link.path))}
                  >
                    {link.title}
                  </InternalLink>
                </li>
              ))}
            </HStack>
            <HStack>
              <HeaderSearchButton selected={searchIsOpen} onClick={handleToggleSearchIsOpen} />
              <MinSideLink sx={headerLinkDecorationStyle()} />
            </HStack>
          </Flex>
        </Stack>
      </HeaderFooterContainer>
      <Collapse in={searchIsOpen} animateOpacity startingHeight={0.1}>
        <SearchComponent id="catalogSearchDesktop" inputRef={searchInputRef} />
      </Collapse>
    </>
  );
};
