import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { ZodSchema } from "zod";
import { useQueryObject } from "./useQueryObject";

type ValidQueryParamStructure = Record<string, undefined | string | number | boolean | string[]>;

/**
 * A hook for storing and persisting state in url as query params. Persists state when navigating, and allows users to share "deep urls" to a specific state. This hook also allows you to update several parameters in the url at once, preventing race conditions.
 * @param {ZodSchema} zodSchema A zod schema that defines the data structure you want to store in the url and is used to validate parameters. Query params must be treated as user-generated data since a user might maniplulate it manually or a malicious actor might share url's with bad/invalid query params.
 * @returns [state, updateState, getUrlToNewState]
 * @example
  const [state, updateState, getUrlToNewState] = useMultipleUrlStates(
    z.object({
      myString: z.string().optional(),
      myStingArray: z.array(z.string()).optional(),
      myNumber: z.number().optional(),
      myBoolean: z.boolean().optional(),
    })
  );

  return (
    <div>
      <button onClick={() => updateState({ myString: "Hello" })}>Update partial state</button>
      <button onClick={() => updateState({ myString: "World", myStingArray: ["My value"], myNumber: 10 })}>
        Update several parameters at once
      </button>
      <button onClick={() => updateState({ myString: undefined, myStingArray: undefined, myNumber: undefined })}>
        Unset several parameters
      </button>
      <a href={getUrlToNewState({ myString: "From a link", myStingArray: undefined })}>Link to a new state</a>
      <pre>{JSON.stringify(state, null, 2)}</pre>
    </div>
  );
 */
export function useMultipleUrlStates<Data extends ValidQueryParamStructure>(
  zodSchema: ZodSchema<Data>
): [Data | undefined, (change: Partial<Data>) => void, (change: Partial<Data>) => string] {
  const router = useRouter();

  const currentState = useCurrentState(zodSchema);

  const pathName = usePathname();
  const searchParams = useSearchParams();

  const getUrlToNewState = (change: Partial<Data>): string => {
    // Persists exsiting query-params
    const clonedSearchParams = new URLSearchParams(searchParams);

    // Update query params with changes. Removes key from url if the updated value equals undefined
    Object.entries(change).forEach(([key, value]) =>
      value ? clonedSearchParams.set(key, JSON.stringify(value)) : clonedSearchParams.delete(key)
    );

    // TODO hvordan ta vare på andre ting som feks #hash i url
    return `${pathName}?${clonedSearchParams.toString()}`;
  };

  const setState = (change: Partial<Data>) => router.replace(getUrlToNewState(change), { scroll: false });

  return [currentState, setState, getUrlToNewState];
}

const useCurrentState = <Data>(schema: ZodSchema<Data>) => {
  const params = useQueryObject();

  const parsedQuery = safelyJSONParseAllParms(params);

  // Validates that the data we get from the url has the types and structure we're expecting
  const validated = schema.safeParse(parsedQuery);

  return validated.success ? validated.data : undefined;
};

const safelyJSONParseAllParms = (params: Record<string, string>) =>
  Object.fromEntries(Object.entries(params).map(([key, value]) => [key, safeParseJSON(value)]));

const safeParseJSON = (value: string) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    // Handle invalid JSON
    return undefined;
  }
};
