import { ChangeEventHandler, ComponentProps, FormEventHandler, useCallback, useEffect, useState } from "react";
import { useUrlState } from "../../../utils/hooks/useUrlState";
import { searchParameterName } from "../../searchApi/constants";
import { useSearchContext } from "./SearchContext";
import { SearchInput } from "./SearchInput";

type SearchViewInputProps = Omit<ComponentProps<typeof SearchInput>, "onSubmit" | "value" | "onChange" | "clear">;

export const SearchViewInput = (props: SearchViewInputProps) => {
  const { loading } = useSearchContext();
  const [inputValue, setInputValue] = useState("");
  const [searchQuery, setSearchQuery] = useUrlState<string>("", searchParameterName);

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setInputValue(event.target.value);
  };

  const onSubmit: FormEventHandler = useCallback(
    (event) => {
      event.preventDefault();
      setSearchQuery(inputValue);
    },
    [inputValue, setSearchQuery]
  );

  const clear = () => {
    setInputValue("");
  };

  // Update input value when mounting page with a defined searchQuery (i.e. .../sok?s=<searchQuery>)
  useEffect(() => {
    if (loading && searchQuery && !inputValue) setInputValue(searchQuery);
  }, [loading, inputValue, searchQuery]);

  return (
    <SearchInput
      value={inputValue}
      onChange={onChange}
      onSubmit={onSubmit}
      loading={loading}
      clear={clear}
      {...props}
    />
  );
};
